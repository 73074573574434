import React from "react";
import { useParams } from "react-router-dom";
// import DrivenSales from "../components/OverviewData/DrivenSales/DrivenSales";
import OverallRating from "../components/OverviewData/OverallRating/OverallRating";
// import EmailCredits from "../components/OverviewData/EmailCredits/EmailCredits";
import { setAxiosHeader } from "../../../shared/utils/axiosHeaders";
import { setCookie } from "../../../shared/utils/cookie";
import classes from "./Overall.module.css";

const Overall = () => {
  const { shopId, clientToken } = useParams<{
    clientToken: string;
    shopId: string;
  }>();
  if (clientToken && shopId) {
    setAxiosHeader(clientToken, shopId);
    setCookie("clientToken", clientToken, 365);
    setCookie("shopId", shopId, 365);
  }

  return (
    <div>
      <div className={classes.Row}>
        {/* <div className={classes.DataColumn}>
          <EmailCredits />
        </div> */}

        <div className={classes.DataColumn}>
          <OverallRating />
        </div>
        {/* <div className={classes.DataColumn}>
          <DrivenSales />
        </div> */}
      </div>
    </div>
  );
};

export default Overall;
