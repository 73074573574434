import { SettingActions } from "../actions/types/settingActions";
import { ActionTypes } from "../actions/types/actionTypes";
import updateObject from "../../shared/utils/updateObject";

export enum EAutoPublish {
  All = 1,
  FiveStar = 5,
  Above4 = 4,
  Above3 = 3,
  Off = 6,
}

export enum EEmailTiming {
  Off = 0,
  FiveDays = 5,
  TenDays = 10,
  FifteenDays = 15,
  TwentyDays = 20,
  TwentyfiveDays = 25,
  ThirtyDays = 30,
}

export enum EFont {
  Roboto,
  OpenSans,
  Montserrat,
  SourceSansPro,
  RobotoCondensed,
  Oswald,
  RobotoMono,
  RaleWay,
  NotoSans,
  RobotoSlab,
}

export enum EWidgetColors {
  Black,
  White,
  Custom,
}

export enum EReviewShadow {
  Basic,
  Light,
  Dark,
}

export enum EReviewRadius {
  Sharp,
  LightRounded,
  Rounded,
  ExtraRounded,
}

export interface IWidgetCustom {
  headerText: string;
  buttonBorder: string;
  buttonText: string;
  buttonTextOnHover: string;
  buttonBackground: string;
  buttonBackgroundOnHover: string;
  reviewText: string;
  reviewBackground: string;
  reviewBackgroundOnHover: string;
  verifiedBadge: string;
}

export interface IAppearance {
  starColor: string;
  font: EFont;
  widgetColors: EWidgetColors;
  widgetCustom: {
    headerText: string;
    buttonBorder: string;
    buttonText: string;
    buttonTextOnHover: string;
    buttonBackground: string;
    buttonBackgroundOnHover: string;
    reviewText: string;
    reviewBackground: string;
    reviewBackgroundOnHover: string;
    verifiedBadge: string;
  };
  reviewShadow: EReviewShadow;
  reviewRadius: EReviewRadius;
  showWriteReviewButton: boolean;
  showDate: boolean;
  showPopup: boolean;
}

export interface ICollectReview {
  autoPublish: EAutoPublish;
  emailTiming: EEmailTiming;
  isDiscount: boolean;
  discountCode?: string;
  discountContent?: string;
}

export interface ISettings {
  appearance: IAppearance;
  collectReview: ICollectReview;
}

export interface ISettingReducer {
  settings: ISettings | null;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
}

const initialState: ISettingReducer = {
  settings: null,
  loading: false,
  error: false,
};

const reducer = (state = initialState, actions: SettingActions) => {
  switch (actions.type) {
    case ActionTypes.SETTING_REQUEST_START:
      return updateObject(state, { loading: true, error: false });
    case ActionTypes.SETTING_REQUEST_FAIL:
      return updateObject(state, {
        loading: false,
        error: true,
        errorMessage: actions.errorMessage,
      });

    case ActionTypes.FETCH_SETTING_SUCCESS:
      return updateObject(state, {
        settings: actions.settings,
        loading: false,
        error: false,
      });

    case ActionTypes.UPDATE_SETTING_SUCCESS:
      const settings = state.settings!;
      const updates = Object.keys(actions.updatedField);
      updates.forEach(async (update) => {
        if (update === "appearance") {
          settings.appearance = {
            ...settings.appearance,
            ...actions.updatedField.appearance,
            widgetCustom: {
              ...settings.appearance.widgetCustom,
              ...actions.updatedField.appearance.widgetCustom,
            },
          };
        } else if (update === "collectReview") {
          settings.collectReview = {
            ...settings.collectReview,
            ...actions.updatedField.collectReview,
          };
        }
      });
      return updateObject(state, {
        settings,
        loading: false,
        error: false,
      });

    default:
      return state;
  }
};

export default reducer;
