import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSetting } from "../../../store/actions/index";
import { IStoreState } from "../../../store/reducers/index";
import classes from "./Settings.module.css";
import LoadingScreenHandler from "../../../shared/components/Elements/LoadingScreenHandler/LoadingScreenHandler";
import Appearance from "../components/Appearance/Appearance";
import CollectReview from "../components/CollectReview/CollectReview";
import InstallGuide from "../components/InstallGuide/InstallGuide";

const Settings = () => {
  const { settings, loading, error, errorMessage } = useSelector(
    (state: IStoreState) => state.settings
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSetting());
  }, [dispatch]);

  const settingsElement = settings && (
    <div>
      <div className={classes.SettingField}>
        <InstallGuide />
      </div>
      <div className={classes.SettingField}>
        <Appearance appearance={settings.appearance} />
      </div>
      <div className={classes.SettingField}>
        <CollectReview collectReview={settings.collectReview} />
      </div>
    </div>
  );

  return (
    <div className={classes.Settings}>
      {settingsElement}
      <LoadingScreenHandler
        loading={loading}
        error={error}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default Settings;
