import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SettingField from "../SettingField/SettingField";
import StarColor from "./StarColor/StarColor";
import { IAppearance, EWidgetColors } from "../../../../store/reducers/settings";
import Font from "./Font/Font";
import WidgetColors from "./WidgetColors/WidgetColors";
import WidgetCustom from "./WidgetCustom/WidgetCustom";
import ReviewShadow from "./ReviewShadow/ReviewShadow";
import ReviewRadius from "./ReviewRadius/ReviewRadius";
import WriteReviewButton from "./WriteReviewButton/WriteReviewButton";
import ReviewDate from "./ReviewDate/ReviewDate";
import Popup from "./Popup/Popup";
import { updateSetting } from "../../../../store/actions";
import updateObject from "../../../../shared/utils/updateObject";

interface IAppearanceProps {
  appearance: IAppearance;
}

const Appearance: React.FC<IAppearanceProps> = (props) => {
  const [updatedAppearance, setUpdatedAppearance] = useState(props.appearance);
  const [updatedAppearanceFields, setUpdatedAppearanceFields] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const keys = Object.keys(updatedAppearance);
    let isSame = true;
    let updatedFields = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const initialAppearanceValue = props.appearance[key as "widgetCustom"];
      const currentAppearanceValue = updatedAppearance[key as "widgetCustom"];

      if (key === "widgetCustom") {
        let updatedWidgetCustomFields = {};
        const initialWidgetCustomValues = props.appearance.widgetCustom;
        const currentWidgetCustomValues = updatedAppearance.widgetCustom;
        const widgetCustomKeys = Object.keys(initialWidgetCustomValues);
        for (let m = 0; m < widgetCustomKeys.length; m++) {
          const widgetCustomKey = widgetCustomKeys[m] as "headerText";
          const initialWidgetCustomValue =
            initialWidgetCustomValues[widgetCustomKey];
          const currentWidgetCustomValue =
            currentWidgetCustomValues[widgetCustomKey];
          if (initialWidgetCustomValue !== currentWidgetCustomValue) {
            updatedWidgetCustomFields = updateObject(
              updatedWidgetCustomFields,
              { [widgetCustomKey]: currentWidgetCustomValue }
            );
            updatedFields = updateObject(updatedFields, {
              widgetCustom: updatedWidgetCustomFields,
            });
            isSame = false;
          }
        }
      } else if (initialAppearanceValue !== currentAppearanceValue) {
        isSame = false;
        updatedFields = { ...updatedFields, [key]: currentAppearanceValue };
      }
    }

    setIsButtonDisabled(isSame);
    setUpdatedAppearanceFields(updatedFields);
  }, [updatedAppearance, props.appearance]);

  const saveButtonClickHandler = () => {
    dispatch(updateSetting({ appearance: updatedAppearanceFields }));
  };

  return (
    <SettingField
      header="Hiển thị"
      onSaveClick={saveButtonClickHandler}
      isSaveBtnDisabled={isButtonDisabled}
    >
      <StarColor
        value={updatedAppearance.starColor}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            starColor: e.target.value,
          });
        }}
      />
      <Font
        value={updatedAppearance.font}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            font: Number(e.target.value),
          });
        }}
      />
      <WidgetColors
        value={updatedAppearance.widgetColors}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            widgetColors: Number(e.target.value),
          });
        }}
      />
      {updatedAppearance.widgetColors === EWidgetColors.Custom && (
        <WidgetCustom
          value={updatedAppearance.widgetCustom}
          onChange={(key, value) => {
            setUpdatedAppearance({
              ...updatedAppearance,
              widgetCustom: { ...updatedAppearance.widgetCustom, [key]: value },
            });
          }}
        />
      )}
      <ReviewShadow
        value={updatedAppearance.reviewShadow}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            reviewShadow: Number(e.target.value),
          });
        }}
      />
      <ReviewRadius
        value={updatedAppearance.reviewRadius}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            reviewRadius: Number(e.target.value),
          });
        }}
      />
      <WriteReviewButton
        value={Number(updatedAppearance.showWriteReviewButton)}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            showWriteReviewButton: !!Number(e.target.value),
          });
        }}
      />
      <ReviewDate
        value={Number(updatedAppearance.showDate)}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            showDate: !!Number(e.target.value),
          });
        }}
      />
      <Popup
        value={Number(updatedAppearance.showPopup)}
        onChange={(e) => {
          setUpdatedAppearance({
            ...updatedAppearance,
            showPopup: !!Number(e.target.value),
          });
        }}
      />
    </SettingField>
  );
};

export default Appearance;
