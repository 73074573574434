import React, { useState } from "react";
import axios from "axios";

import Card from "../../../../shared/components/Elements/Card/Card";
import Button from "../../../../shared/components/Elements/Button/Button";
import LoadingScreenHandler from "../../../../shared/components/Elements/LoadingScreenHandler/LoadingScreenHandler";
import Modal from "../../../../shared/components/Elements/Modal/Modal";
import classes from "./InstallGuide.module.css";
import Step2Img from "../../../../assets/images/codeInstruction/step2-v2.png";
import Step3Img from "../../../../assets/images/codeInstruction/step3-v2.png";

const InstallGuide = () => {
  const [requestStatus, setRequestStatus] = useState({
    isError: false,
    isLoading: false,
    isSuccess: false,
  });
  const [showStep, setShowStep] = useState(false);

  const onClickHandler = () => {
    setRequestStatus({ isLoading: true, isError: false, isSuccess: false });
    axios({
      method: "POST",
      url: "/client/settings/updateAssets",
    })
      .then(() => {
        setRequestStatus({ isLoading: false, isError: false, isSuccess: true });
      })
      .catch(() => {
        setRequestStatus({ isLoading: false, isError: true, isSuccess: false });
      });
  };

  return (
    <Card>
      <div className={classes.Header} onClick={() => setShowStep((p) => !p)}>
        Hướng dẫn đồng bộ Ảnh Nổi với giao diện
      </div>

      {showStep && (
        <div className={classes.StepsContainer}>
          <div className={classes.StepWrapper}>
            <div className={classes.StepTitle}>Bước 1:</div>
            <div>
              Nhấn vào nút sau để đồng bộ với giao diện, sau đó vào trang sản
              phẩm và kiểm tra, nếu chưa thấy mục đánh giá từ Ảnh Nổi thì tiếp
              tục bước 2.
            </div>
            <Button onClick={onClickHandler}>Đồng bộ</Button>
            <LoadingScreenHandler
              loading={requestStatus.isLoading}
              error={requestStatus.isError}
              errorMessage="Gặp sự cố khi đồng bộ với giao diện hiện tại. Xin vui lòng vui lòng thử lại sau."
            />
            <Modal
              show={requestStatus.isSuccess}
              backdropClicked={() => {
                setRequestStatus((prev) => ({ ...prev, isSuccess: false }));
              }}
            >
              <div>Đã đồng bộ!</div>
              <div className={classes.CloseModalBtn}>
                <Button
                  size="small"
                  onClick={() => {
                    setRequestStatus((prev) => ({ ...prev, isSuccess: false }));
                  }}
                >
                  Đóng
                </Button>
              </div>
            </Modal>
          </div>

          <div className={classes.StepWrapper}>
            <div className={classes.StepTitle}>Bước 2:</div>
            <div>
              Tìm đến file chi tiết sản phẩm <b>product.bwt</b>. Dán đoạn mã này
              vào vị trí mà bạn mong muốn và lưu lại. Đoạn mã này sẽ thể hiện
              mục đánh giá.
            </div>
            <div className={classes.Code}>
              <code>{`<div id="anhnoiReviews" data-product-id="{{product.id}}" class="page-width"></div>`}</code>
            </div>
            <img className={classes.Image} alt="step1" src={Step2Img} />
          </div>

          <div className={classes.StepWrapper}>
            <div className={classes.StepTitle}>Bước 3 (tuỳ chọn):</div>
            <div>
              Kiểm tra xem danh sách sản phẩm đã có phần sao dưới tên mỗi sản
              phẩm chưa, nếu chưa hãy copy đoạn mã sau và dán vào phần code sinh
              ra danh sách sản phẩm. Thông thường bạn nên để dưới phần tên sản
              phẩm.
            </div>
            <div className={classes.Code}>
              <code>{`<a href="#anhnoiReviews"><div class="anhnoi-rating-summary" id="anhnoiRatingSummary" data-product-id="{{product.id}}" data-rating="{{ product.metafields.anhnoiRatingSummary.averageRating }}" data-num-reviews="{{ product.metafields.anhnoiRatingSummary.numberOfReviews }}" ></div></a>`}</code>
            </div>
            <img className={classes.Image} alt="step3" src={Step3Img} />
          </div>
        </div>
      )}
    </Card>
  );
};

export default InstallGuide;
